/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from './Navbar.module.css';
import { useState } from 'react';
import { FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { AiFillYoutube, AiFillInstagram } from 'react-icons/ai';
import { HiOutlineMenuAlt4 } from 'react-icons/hi';
import { BiRightArrow } from 'react-icons/bi';
import logo from '../../assets/logo.png';

export default function Navbar() {
  const [showNav, setShowNav] = useState(false);

  const handleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <img className= "logoImg" src={logo} alt="logo" />
          <ul className={styles.links}>
            <a href="#home">
              <li>Home</li>
            </a>

            <a href="#about">
              <li>About</li>
            </a>
            <a href="#services">
              <li>Services</li>
            </a>
            <a href="#footer">
              <li>Contact</li>
            </a>
          </ul>
        </div>
        <div className={styles.rightHeader}>
          <FaLinkedinIn className={styles.icon} />
          <AiFillYoutube className={styles.icon} />
          <FaTwitter className={styles.icon} />
          <AiFillInstagram className={styles.icon} />

          <div className={styles.cta}>
          <a href="#footer">
            <button id={styles.topCta} className="primaryBtn">
              Get Quote <BiRightArrow />
            </button>
            </a>
          </div>
        </div>
      </div>

      <div className={styles.mobContainer}>
      <img className= "logoImg" src={logo} alt="logo" />
        <HiOutlineMenuAlt4 onClick={handleNav} className={styles.menu} />
        <div
          onClick={handleNav}
          className={`${styles.overlay} ${showNav ? styles.showOverlay : ''}`}
        />
        <div
          className={`${styles.content} ${showNav ? styles.showContent : ''}`}>
          <ul className={styles.links}>
            <a href="#home">
              <li>Home</li>
            </a>
            <a href="#about">
              <li>About</li>
            </a>
            <a href="#services">
              <li>Services</li>
            </a>
            <a href="#footer">
              <li>Contact</li>
            </a>
          </ul>

          <div className={styles.socials}>
            <FaLinkedinIn className={styles.icon} />
            <AiFillYoutube className={styles.icon} />
            <FaTwitter className={styles.icon} />
            <AiFillInstagram className={styles.icon} />
          </div>
        </div>
      </div>
    </>
  );
}
