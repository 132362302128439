import React from 'react';
import styles from './About.module.css';
import image2 from '../../assets/image2.jpg';
import hero1 from "../../assets/hero1.png"
//import './stats.js'

const About = () => {
  return (
    <div id="about" className={styles.shorts}>
      <h2>WHO WE ARE</h2>
      <div className={styles.short}>
        <div className={styles.leftShort}>
          <h2>
            Crafting Imagination for<br></br> <span>25+ Years</span>
          </h2>
          <p>
            Introducing Simran Graphics, a stalwart in the marketing realm with an illustrious quarter-century legacy.
            Recognized for our inventive strategies and client-centric ethos, we've consistently delivered remarkable outcomes.
            Our extensive repertoire boasts esteemed clientele from across the city.
          </p>
        </div>
        <div className={styles.rightShort}>
          <img src={image2} alt="" />
        </div>
      </div>
      <div className={`${styles.short} reverse`}>
        <div className={styles.leftShort}>
          <h2>
            Your Trusted <br /> <span>Design & Printing Partner</span>
          </h2>
          <p >
            At Simran Graphics, we harness decades of industry wisdom to curate bespoke marketing solutions that propel
            engagement and prosperity. Our team of adept professionals excels in devising avant-garde campaigns that resonate seamlessly across diverse platforms.
            Powered by strategic partnerships and data-driven acumen, we consistently achieve exceptional ROI for our patrons.
          </p>
        </div>
        <div className={styles.rightShort}>
          <img src={hero1} alt="" />
        </div>
      </div>
      <br />
      <hr className="hrstyle" />
      <div className={styles.lastInfo}>
      
        <p>Our 25-year journey is a testament to our unwavering pursuit of excellence, artistic flair, and resolute commitment. As we stride into the future,
          we remain resolute in charting new territories within the dynamic marketing landscape. Join us in shaping the next chapter in marketing evolution.</p>
          
      <div className={styles.container}>
    
      <div className={styles.stats}>
        <h3>Projects Completed</h3>
        <p className='value' akhi="2500">2500+</p><span ></span>
      </div>
      <div className={styles.stats}>
        <h3>Happy Clients</h3>
        <p className='value' akhi="500">500+</p>
      </div>
      <div className={styles.stats}>
        <h3>Expert Team</h3>
        <p className='value' akhi="20">20+</p>
      </div>
      <div className={styles.stats}>
        <h3>Success Years</h3>
        <p className='value' akhi="25">25+</p>
      </div>
    </div>
      </div>
    </div>

  );
};

export default About;
