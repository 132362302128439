/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styles from './Footer.module.css';
import { BiLinkExternal } from 'react-icons/bi';
import { LiaCopyrightSolid } from 'react-icons/lia';
import { CiLocationOn } from 'react-icons/ci';
import { AiOutlinePhone } from 'react-icons/ai';
import { AiOutlineMail } from 'react-icons/ai';
import logo from '../../assets/logo.png';

export default function Footer() {
  return (
    <div id="footer" className={styles.footer}>
      <img id={styles.bottomCut} src="../bottomCut.png" alt="" />
      <div className={`${styles.col} ${styles.first}`}>
        <div className={styles.brand}>
        <img className= "logoImg"  src={logo} alt="logo" />
          <p>
            Experience unparalleled quality, meticulous attention to detail, and
            a dedication to meeting deadlines with Simran Graphics.
          </p>
        </div>
        <div className={styles.links}>
        <ul>
        <li>Contact</li>
          <a href="#">
            <li><CiLocationOn className={styles.linkIcon}/> Kalkaji, New Delhi</li>
            </a>
            <a href="#">
              <li><AiOutlinePhone className={styles.linkIcon}/>9582621942, 9810665518</li>
            </a>
            <a href="#">
              <li><AiOutlineMail className={styles.linkIcon}/> contact@simrangraphics.in </li>
            </a>
            <a href="#">
              <li><AiOutlineMail className={styles.linkIcon}/> simranjgraphics@yahoo.com</li>
            </a>
          </ul>
          <ul></ul>
          <ul>
            <li>Navigation</li>
            <a href="#">
              <li>Home</li>
            </a>
            <a href="#">
              <li>About</li>
            </a>
            <a href="#">
              <li>Services</li>
            </a>
            <a href="#">
              <li>Portfolio</li>
            </a>
          </ul>
          <ul>
            <li>Socials</li>
            <li>
              Instagram <BiLinkExternal className={styles.linkIcon} />
            </li>
            <li>
              Twitter <BiLinkExternal className={styles.linkIcon} />
            </li>
            <li>
              Youtube <BiLinkExternal className={styles.linkIcon} />
            </li>
            <li>
              LinkedIn <BiLinkExternal className={styles.linkIcon} />
            </li>
          </ul>
        </div>
      </div>
      <div className={`${styles.col} ${styles.newsletter}`}>
        {/* <p>Connect</p> */}
        <h3>Connect with Simran Graphics</h3>
        <form className={styles.form}>
          <input type="text" placeholder="Name" />
          <input type="email" placeholder="Email" />
          <input type="text" placeholder="Subject" />
          <textarea rows={5} placeholder="Message" />
          <button>Send Enquiry</button>
        </form>
      </div>

      {/* <div className="copyright">
                    <span>Copyright<LiaCopyrightSolid className={styles.linkIcon}/>2023 Simran Graphics. All rights reserved</span>
                </div> */}
    </div>
  );
}
