/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styles from './Intro.module.css';
import { BiRightArrow } from 'react-icons/bi';
import { FaBriefcase } from 'react-icons/fa';
import client1 from '../../assets/client1.png';
import client2 from '../../assets/client2.png';
import client3 from '../../assets/client3.png';
import client4 from '../../assets/client4.png';
import client5 from '../../assets/client5.png';

const Intro = () => {
  return (
    <div id="home"className={styles.container}>
      <h1>
        Your pathway to printing excellence!<br /><span>Simran Graphics</span>
      </h1>
      <p>
        Experience unparalleled quality, meticulous attention to detail, and a
        dedication to meeting deadlines with Simran Graphics. We’re not just
        printing, we’re crafting paper art. Connect with us today to transform
        your ideas into tangible print realities.
      </p>
      <a href="#footer">
        <button >
          Hire Us <FaBriefcase />
        </button>
      </a>
      
      

      <div className={styles.clients}>
      <hr className="hrstyle" />
      <div className={styles.clientHeading}>
      <p>
        Some of our notable clients, who inspire us to innovate and drive our commitment to excellence.
      </p>
      </div>
        <img src={client1} className="clientImg" alt="" />
        <img src={client2} className="clientImg" alt="" />
        <img src={client3} className="clientImg" alt="" />
        <img src={client4} className="clientImg" alt="" />
        <img src={client5} className="clientImg" alt="" />
      </div>
    </div>
  );
};

export default Intro;
