import React from 'react';
import './services.css';
import offset from '../../assets/offset-printing.jpeg';
import digital from '../../assets/digital-printing.jpg';
import design from '../../assets/design-support.jpg';
import packaging from '../../assets/packaging-printing.jpg';
import speciality from '../../assets/speciality-finishes.jpg';
import large from '../../assets/large-format-printing.jpg';

const Services = () => {
  return (
    <section id="services">
      <div className="serviceText">
        <span className="title">WHAT WE OFFER</span>
        <p className="text">
          At Simran Graphics, we are committed to transforming your creative
          visions into reality through our comprehensive printing services. With
          cutting-edge technology and a team of skilled experts, we offer a
          diverse range of solutions tailored to your unique requirements.
        </p>
      </div>
      <div className="card-container">
        <div className="card">
          <img src={offset} alt="" />
          <div className="card-content">
            <h2>Offset Printing</h2>
            <p>
              Immerse yourself in vibrant color accuracy and precision with our
              advanced offset printing technology. Perfect for high-volume
              projects, magazines, brochures, and more.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={large} alt="" />
          <div className="card-content">
            <h2>Large Format Printing</h2>
            <p>
              Make a bold statement with oversized banners, posters, signage,
              and displays. Our large format printing guarantees striking colors
              and intricate details.
            </p>
          </div>
        </div>
        <div className="card">
          <img src={packaging} alt="" />
          <div className="card-content">
            <h2>Packaging Printing</h2>
            <p>
              Elevate your brand’s packaging with our meticulous printing,
              crafting visually captivating and informative packaging that
              stands out on shelves.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={digital} alt="" />
          <div className="card-content">
            <h2>Digital Printing</h2>
            <p>
              Enjoy quick turnarounds and flexible printing choices with our
              digital printing services. Ideal for small quantities,
              personalized items, and speedy projects.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={speciality} alt="" />
          <div className="card-content">
            <h2>Speciality Finishes</h2>
            <p>
              From embossing and foiling to spot UV and matte coatings, our
              array of specialty finishes adds sophistication and texture to
              your prints.
            </p>
          </div>
        </div>

        <div className="card">
          <img src={design} alt="" />
          <div className="card-content">
            <h2>Design Support</h2>
            <p>
              Need design assistance? Our adept designers can collaborate with
              you to create captivating layouts that encapsulate your brand’s
              identity.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Services;
