import Navbar from './components/Navbar/Navbar';
import Intro from './components/Intro/Intro';
import Services from './components/Services/services';
import About from './components/About/About';
import Footer from './components/Footer/Footer';
import Stats from './components/Counter/Stats';
import './Global.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Intro />
      <About />
      <Services />
      <Footer />
    </div>
  );
}

export default App;
